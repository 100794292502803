.displayProduct {
	overflow-x: auto;
	max-height: 800px;
}

.previewPDF {
	padding: 10px;
	height: 100vh;
}
.cart-list {
	z-index: 1;
	right: 0;
	top: 0;
}

.searchComponent {
	padding-right: 10%;
	padding-left: 10%;
}

.picBackgroundProduct {
	height: 350px;
	overflow: hidden;
	/* background: black; */
	overflow: hidden;
	margin-bottom: 50px;
	position: relative;
}

.picBackgroundProduct img {
	width: 100%;
	object-position: center center;
	height: 100%;
	object-fit: cover;
	filter: brightness(0.4);
}

.hero-header-text {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #fff;
	z-index: 1;
	font-family: "Roboto", sans-serif;
	width: 100%;
}

@media (max-width: 600px) {
	.searchComponent {
		padding-right: 0px;
		padding-left: 0px;
	}

	.picBackgroundProduct {
		height: 150px;
		margin-bottom: 20px;
	}
	.hero-header-text h1,
	h2 {
		font-size: 1.3em !important;
	}
}
