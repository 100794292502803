.landing-pic {
	width: 100%;
	filter: brightness(50%);
}

.landingPic-pic img {
	width: 100%;
	position: relative;
	background-color: rgba(0, 0, 0, 0.5);
}

.titleSection {
	.title-category {
		letter-spacing: 4px;
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.8em;
	}

	.subtitle-category {
		color: #b2b2b2;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 7px;
		text-transform: uppercase;
	}
}

.features {
	.features-title {
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 4px;
	}
	.features-content {
		font-size: 13px;
	}
}
html {
	scroll-behavior: smooth;
}

.parallax {
	background-image: url("../img/brand/landing2.jpg");
	min-height: 100%;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	h1 {
		color: white;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 4px;
	}
}
.paraPic {
	background-image: url("../img/brand/landing2.jpg");
	min-height: 100%;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	h1 {
		color: white;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 4px;
	}
}

.textLanding {
	h2 {
		@media (min-width: 601px) and (max-width: 1000px) {
			font-size: 1.5rem !important;
		}
		@media (min-width: 401px) and (max-width: 600px) {
			font-size: 1.3rem !important;
		}
		@media (max-width: 400px) {
			font-size: 0.8rem !important;
		}
	}

	h5 {
		@media (min-width: 601px) and (max-width: 1000px) {
			font-size: 1rem !important;
		}

		@media (min-width: 401px) and (max-width: 600px) {
			font-size: 0.8rem !important;
			margin-bottom: 20px !important;
		}
		@media (max-width: 400px) {
			font-size: 0.7rem !important;
		}
	}
}

.section-shaped {
	@media (min-width: 601px) and (max-width: 767px) {
		padding: 40px 0 30px 0 !important;
	}

	@media (min-width: 401px) and (max-width: 600px) {
		padding: 40px 0 30px 0 !important;
	}
	@media (max-width: 400px) {
		padding: 20px 0 30px 0 !important;
	}
}

.fiturKami {
	@media (max-width: 600px) {
		text-align: center;
	}
}

#howToOrder {
	@media (max-width: 600px) {
		padding-bottom: 5%;
		padding-top: 5%;
	}
}

#categoryProduct {
	@media (max-width: 600px) {
		padding-top: 5%;
		padding-bottom: 5%;
	}
}

#productLanding {
	@media (max-width: 600px) {
		padding-top: 5%;
		padding-bottom: 5%;
	}
}

#featuresUs {
	@media (max-width: 600px) {
		padding-bottom: 5%;
		padding-top: 5%;
	}
}

#lineLanding {
	@media (max-width: 600px) {
		padding-bottom: 5%;
		padding-top: 5%;
	}
}

#feedbackLanding {
	@media (max-width: 600px) {
		padding-bottom: 5%;
		padding-top: 15%;
	}
}
