.responsive-img {
	width: 100%;
	height: auto;
}
.cardCategory {
	height: 270px;
	@media (max-width: 600px) {
		height: 170px;
	}
	@media (min-width: 601px) and (max-width: 768px) {
		height: 210px;
	}
	h4 {
		@media (max-width: 768px) {
			font-size: medium;
			padding: 0px;
		}
	}

	.previewCategory {
		height: 150px;

		@media (max-width: 600px) {
			height: 100px;
		}
	}
}
.card__one {
	transition: transform 0.5s;

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
		box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
		content: "";
		opacity: 0;
		z-index: -1;
	}

	&:hover,
	&:focus {
		transform: scale3d(1.006, 1.006, 1);

		&::after {
			opacity: 1;
		}
	}
}

// --------------------------------------------------
.colProductLanding {
	width: 25%;
	padding-left: 20px;
	@media (min-width: 801px) and (max-width: 1024px) {
		width: 33%;
	}
	@media (max-width: 800px) {
		width: 50%;
	}
}

.colProductList {
	padding-left: 10px;

	@media (min-width: 1281px) {
		width: 15%;
	}

	@media (min-width: 1025px) and (max-width: 1280px) {
		width: 25%;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		width: 25%;
	}

	@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
		width: 25%;
	}

	@media (min-width: 601px) and (max-width: 767px) {
		width: 33%;
	}

	@media (max-width: 600px) {
		width: 50%;
	}
}
.colProductList-long {
	padding-left: 10px;

	@media (min-width: 1281px) {
		width: 25%;
	}

	@media (min-width: 768px) and (max-width: 1280px) {
		width: 33%;
	}

	@media (min-width: 601px) and (max-width: 767px) {
		width: 50%;
	}

	@media (max-width: 600px) {
		width: 100%;
	}
}
.cardProduct {
	width: auto;
	padding: 10px;
	height: 290px;
	a {
		color: black !important;
	}

	@media (max-width: 400px) {
		padding: 5px;
	}
}

.badge-features {
	font-size: 0.6rem !important;
	@media (max-width: 400px) {
		font-size: 0.5rem !important;
	}
}
.crop-text-2 {
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.digit {
	width: 100%;
	font-size: 0.9rem;
	color: black;
}
.previewProduct {
	height: 120px;

	width: 100%;
	padding: 5px;
	// position: absolute;
	text-align: center;
	img {
		max-width: 100%;
		height: 100%;
	}
}

.img-hover-zoom {
	overflow: hidden;
	max-height: 250px;
}

.img-hover-zoom img {
	transition: transform 0.5s ease;
}

.cardProduct:hover .img-hover-zoom img {
	transform: scale(1.2);
}

.notify-badge {
	position: absolute;
	right: -15px;
	top: -15px;
	text-align: center;
	border-radius: 20px 20px 20px 20px;
	color: white;
	padding: 3px;
	font-size: 15px;
}
.icon-notify {
	width: 50px;
	height: auto;
}
.icon {
	width: 45px !important;
	height: 45px !important;
}

.card-category .overlay:hover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;
	background-color: rgba(249, 19, 19, 0.9);
	opacity: 0;
	transition: opacity 0.5s;
}

.card__two {
	&::before,
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: opacity 0.38s ease-in-out, transform 0.35s ease-in-out;
		content: "";
		opacity: 0;
		pointer-events: none;
	}

	&::before {
		transform: scale3d(0, 1, 1);
		transform-origin: left top;
		border-top: 1px solid #acacac;
		border-bottom: 1px solid #acacac;
	}

	&::after {
		transform: scale3d(1, 0, 1);
		transform-origin: right top;
		border-right: 1px solid #acacac;
		border-left: 1px solid #acacac;
	}

	&:hover,
	&:focus {
		&::before,
		&::after {
			transform: scale3d(1, 1, 1);
			opacity: 1;
		}
	}
}
