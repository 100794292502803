.notify-badge {
	position: absolute;
	right: 0px;
	top: 0px;
	/* background:red; */
	text-align: center;
	border-radius: 15px 15px 15px 15px;
	color: white;
	padding: 1px;
	font-size: 15px;
}

.hover-one {
	&::before,
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: opacity 0.38s ease-in-out, transform 0.35s ease-in-out;
		content: "";
		opacity: 0;
		pointer-events: none;
	}

	&::before {
		transform: scale3d(0, 1, 1);
		transform-origin: left top;
		border-top: 1px solid #acacac;
		border-bottom: 1px solid #acacac;
	}

	&::after {
		transform: scale3d(1, 0, 1);
		transform-origin: right top;
		border-right: 1px solid #acacac;
		border-left: 1px solid #acacac;
	}

	&:hover,
	&:focus {
		&::before,
		&::after {
			transform: scale3d(1, 1, 1);
			opacity: 1;
		}
	}
}

.logoCompany {
	// @media (min-width: 1600px) {
	// 	width: 25%;
	// }

	// @media (min-width: 768px) and (max-width: 1024px) {
	// 	width: 25%;
	// }

	// @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	// 	width: 25%;
	// }

	// @media (min-width: 481px) and (max-width: 767px) {
	// 	width: 33%;
	// }

	@media (min-width: 320px) and (max-width: 600px) {
		width: 100%;
	}
}

// .logoCompany2 {
// 	@media (min-width: 1600px) {
// 		width: 25%;
// 	}

// @media (min-width: 768px) and (max-width: 1024px) {
// 	width: 25%;
// }

// @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
// 	width: 25%;
// }

// @media (min-width: 481px) and (max-width: 767px) {
// 	width: 33%;
// }

// @media (min-width: 320px) and (max-width: 600px) {
// 	width: 100%;
// }
// }

.navbar-brand {
	// .logoCompany2 {

	width: 60%;
	// }
	img {
		@media (min-width: 320px) and (max-width: 600px) {
			width: auto;
		}
	}

	@media (min-width: 768px) and (max-width: 1600px) {
		// width: 35%;
	}
	@media (min-width: 1201px) {
		// width: 35%;
		width: auto;
	}
}

label {
	text-overflow: ellipsis;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	padding: 0px;
	margin-bottom: 0px !important;
}

.logo-schneider {
	img {
		@media (max-width: 1200px) {
			display: none;
		}
	}
}

.hubungiKami {
	@media (min-width: 950px) and (max-width: 1480px) {
		.btn-inner--icon {
			display: none;
		}
	}
}

.nav-item {
	margin: 0 !important;
}

.nav-link-icon {
	padding: 5px !important;
}
